
<template>
  <div class="fence-container">
    <a-form class="controls" layout="inline">
      <a-form-item>
        <a-button-group>
          <template v-if="drawer.mode === 'none'">
            <a-button @click="drawer.mode = 'polygon'">多边形</a-button>
            <a-button @click="drawer.mode = 'rectangle'">矩形</a-button>
            <a-button @click="drawer.mode = 'circle'">圆形</a-button>
          </template>
          <template v-else>
            <a-button @click="drawer.mode = 'none'">取消</a-button>
          </template>
        </a-button-group>
      </a-form-item>
    </a-form>
    <a-form class="info" layout="inline">
      <a-form-item label="面积" v-show="area > 0"
        >{{ (area / 1000000).toFixed(4) }}平方公里</a-form-item
      >
      <a-form-item>
        <a-button size="small" shape="circle" icon="copy" @click="showModal" />
      </a-form-item>
      <a-form-item label="卫星图">
        <a-switch v-model="satellite" />
      </a-form-item>
    </a-form>

    <amap async :center.sync="center" :zoom.sync="zoom">
      <amap-satellite-layer :visible="satellite" />
      <amap-scale />
      <amap-marker v-if="position" :position.sync="position" />
      <amap-mouse-tool
        :mode.sync="drawer.mode"
        clear-when-close
        @draw="onDraw"
      />
      <div v-if="show">
        <amap-polygon
        :path="AREA1"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
      />
      <amap-polygon
        :path="AREA2"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
      />
      <amap-polygon
        :path="AREA3"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
      />
      </div>

      <amap-polygon
        v-if="drawer.polygon"
        :path.sync="drawer.polygon"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
        editable
      />
      <amap-circle
        v-if="drawer.circle"
        :center.sync="drawer.circle.center"
        :radius.sync="drawer.circle.radius"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
        editable
      />
      <amap-rectangle
        v-if="drawer.rectangle"
        :bounds.sync="drawer.rectangle"
        :fill-color="styles.fill"
        :stroke-color="styles.stroke"
        :fill-opacity="0.5"
        editable
      />
    </amap>
  </div>
</template>

<script>
import {
  Amap,
  Marker,
  Scale,
  MouseTool,
  Polygon,
  Circle,
  Rectangle,
  SatelliteLayer,
} from "@amap/amap-vue";

function makeBounds(value) {
  const sw = new window.AMap.LngLat(value[0][0], value[0][1]);
  const ne = new window.AMap.LngLat(value[1][0], value[1][1]);
  return new window.AMap.Bounds(sw, ne);
}

export default {
  components: {
    Amap,
    AmapMarker: Marker,
    AmapScale: Scale,
    AmapMouseTool: MouseTool,
    AmapPolygon: Polygon,
    AmapCircle: Circle,
    AmapRectangle: Rectangle,
    AmapSatelliteLayer: SatelliteLayer,
  },
  data() {
    return {
      center: [118.859307,28.970229],
      position: [118.859307,28.970229],
      zoom: 16,
      ready: false,
      AREA1:[
  [
    118.858861,
    28.971505
  ],
  [
    118.859705,
    28.9715
  ],
  [
    118.85999,
    28.971392
  ],
  [
    118.859984,
    28.97092
  ],
  [
    118.859724,
    28.97092
  ],
  [
    118.859231,
    28.970858
  ],
  [
    118.858822,
    28.970949
  ]
],
AREA2:[
[
  [
    118.860286,
    28.970258
  ],
  [
    118.861291,
    28.970045
  ],
  [
    118.861163,
    28.969632
  ],
  [
    118.860098,
    28.969856
  ]
]
],
AREA3:[
  [
    118.855471,
    28.9733
  ],
  [
    118.855518,
    28.970053
  ],
  [
    118.855721,
    28.96997
  ],
  [
    118.857052,
    28.969957
  ],
  [
    118.858321,
    28.969984
  ],
  [
    118.858431,
    28.970094
  ],
  [
    118.85829,
    28.97219
  ],
  [
    118.858024,
    28.972368
  ],
  [
    118.857804,
    28.972313
  ]
],
      styles: {
        fill: "#409EFF",
        stroke: "#000A58",
      },
      satellite: true,
      drawer: {
        type: "none",
        mode: "none",
        polygon: [],
        circle: {},
        rectangle: null,
      },
      show:false
    };
  },
  computed: {
    area() {
      const { ready, drawer } = this;
      if (!ready) return 0;
      switch (drawer.type) {
        case "none":
          return 0;
        case "polygon":
          return window.AMap.GeometryUtil.ringArea(drawer.polygon);
        case "rectangle":
          return new window.AMap.Rectangle({
            bounds: makeBounds(drawer.rectangle),
          }).getArea();
        case "circle":
          return Math.PI * Math.pow(drawer.circle.radius, 2);
        default:
          return 0;
      }
    },
  },
  methods: {
    onDraw(type, res) {
      this.drawer.type = type;
      this.drawer[type] = res;
      this.drawer.mode = "none";
    },
    showModal() {
      const h = this.$createElement;
      this.$success({
        title: "当前圈选的范围",
        content: h("a-textarea", {
          attrs: {
            style: "height: 400px; font-family: monospace;",
          },
          props: {
            value: JSON.stringify(this.drawer[this.drawer.type], null, "  "),
          },
        }),
      });
    },
  },
  watch: {
    "drawer.mode"(mode) {
      console.log("drawer.mode", mode);
      if (mode !== "none") {
        this.show=false
        this.$emit('success',false)
        const { drawer } = this;
        drawer.type = "none";
        drawer.polygon = null;
        drawer.circle = null;
        drawer.rectangle = null;
      }else{
        this.$emit('success',true)
        this.show=true
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fence-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.controls {
  position: absolute;
  left: 6px;
  top: 0;
  z-index: 9999;
}
.info {
  position: absolute;
  right: 6px;
  top: 0;
  z-index: 9999;
}
</style>
